import Cookies from 'js-cookie';

const state = {
  userCode:Cookies.get('PRODUCTION' + '_userCode'),
  accessToken:Cookies.get('PRODUCTION' + '_accessToken') || '',
}
const mutations = {
  USER_CODE(state, name) {
    state.userCode = name
    Cookies.set('PRODUCTION_userCode', name);
  },
  ACCESS_TOKEN(state, name) {
    state.accessToken = name
    Cookies.set('PRODUCTION' + "_accessToken", name);
  }
  
}
const actions = {
  // 设置name
  userCode({ commit }, name) {
    commit('USER_CODE', name)
  },
  accessToken({ commit }, name) {
    commit('ACCESS_TOKEN', name)
  }
}
export default {
  state,
  mutations,
  actions
}
