/**
 * 基础路由
 * @type { *[] }
 */
 export const constantRouterMap = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/indexs/index'),
    meta: {
      title: '首页',
      keepAlive: false
    }
  },
  {
    path: '/collection',
    name: '分类列表',
    component: () => import('@/views/typeList/index'),
    meta: {
      title: '分类列表',
      keepAlive: false
    }
  },
  {
    path: '/shop',
    name: '商城',
    component: () => import('@/views/shop/index'),
    meta: {
      title: '商城',
      keepAlive: false
    }
  },
  {
    path: '/author',
    name: '作者主页',
    component: () => import('@/views/typeList/author'),
    meta: {
      title: '作者主页',
      keepAlive: false
    }
  },
  {
    path: '/detail',
    name: '作品详情',
    component: () => import('@/views/typeList/detail'),
    meta: {
      title: '作品详情',
      keepAlive: false
    }
  },
  {
    path: '/buy',
    name: '作品详情',
    component: () => import('@/views/typeList/buy'),
    meta: {
      title: '购买藏品',
      keepAlive: false
    }
  },
  {
    path: '/pay',
    name: '支付',
    component: () => import('@/views/typeList/pay'),
    meta: {
      title: '支付',
      keepAlive: false
    }
  },
  {
    path: '/radicalDreamer',
    name: '作者主页',
    component: () => import('@/views/radicalDreamer/index'),
    meta: {
      title: '作者主页',
      keepAlive: false
    }
  },
  {
    path: '/news',
    name: '新闻资讯',
    component: () => import('@/views/data/news'),
    meta: {
      title: '新闻资讯',
      keepAlive: false
    }
  },
  {
    path: '/newsDetail',
    name: '详情',
    component: () => import('@/views/data/newsDetail'),
    meta: {
      title: '详情',
      keepAlive: false
    }
  },
  {
    path: '/rank',
    name: '排名',
    component: () => import('@/views/data/rankList'),
    meta: {
      title: '排名',
      keepAlive: false
    }
  },
  {
    path: '/actived',
    name: '交易记录',
    component: () => import('@/views/data/actived'),
    meta: {
      title: '交易记录',
      keepAlive: false
    }
  },
  {
    path: '/created',
    name: '创建',
    component: () => import('@/views/created/index'),
    meta: {
      title: '创建',
      keepAlive: false
    }
  },
  {
    path: '/shop',
    name: '商城',
    component: () => import('@/views/typeList/shop'),
    meta: {
      title: '商城',
      keepAlive: false
    }
  },
  {
    path: '/free',
    name: '免费图片',
    component: () => import('@/views/free/index'),
    meta: {
      title: '免费图片',
      keepAlive: false
    }
  },
  {
    path: '/freeDetail',
    name: '图片详情',
    component: () => import('@/views/free/detail'),
    meta: {
      title: '图片详情',
      keepAlive: false
    }
  },
  {
    path: '/helpCenter',
    name: '帮助中心',
    component: () => import('@/views/helpCenter/index'),
    meta: {
      title: '帮助中心',
      keepAlive: false
    }
  },
  {
    path: '/helpDetail',
    name: '帮助中心',
    component: () => import('@/views/helpCenter/helpDetail'),
    meta: {
      title: '帮助中心',
      keepAlive: false
    }
  },
  {
    path: '/rule',
    name: '铸造守则',
    component: () => import('@/views/created/rule'),
    meta: {
      title: '铸造守则',
      keepAlive: false
    }
  },
  {
    path: '/serviceagree',
    name: '注册守则',
    component: () => import('@/views/created/serviceagree'),
    meta: {
      title: '注册守则',
      keepAlive: false
    }
  },
  {
    path: '/privacys',
    name: '用户协议',
    component: () => import('@/views/created/privacys'),
    meta: {
      title: '用户协议',
      keepAlive: false
    }
  },
  {
    path: '/search',
    name: '搜索',
    component: () => import('@/views/search/index'),
    meta: {
      title: '搜索',
      keepAlive: false
    }
  },
  {
    path: '/ucenterEditInfo',
    name: '修改资料',
    component: () => import('@/views/radicalDreamer/ucenterEditInfo'),
    meta: {
      title: '修改资料',
      keepAlive: false
    }
  },
  {
    path: '/user',
    name: '用户中心',
    component: () => import('@/views/radicalDreamer/user'),
    meta: {
      title: '用户中心',
      keepAlive: false
    }
  }
]
